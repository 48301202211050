import { usePageStore } from '@voix/store/pageStore'

export default function () {
  const pageStore = usePageStore()
  const siteId = computed<number>(() => ((pageStore.currentPage as { site_id: number })?.site_id) || 1)
  const languageCode = computed<string>(() => (pageStore.currentPage as { language_code: string })?.language_code || 'en')

  return {
    siteId,

    getCommonLayoutData: async (isVoixTemplatizer = false, exceptions: Array<string> = [], forceCurrentResort: boolean = false) => {
      const resorts: any = ref(null)
      const brands: any = ref(null)
      const currentResort: any = ref(null)
      const footerCompanyMenu: any = ref(null)
      const footerResourcesMenu: any = ref(null)
      const footerTermsMenu: any = ref(null)

      if (!isVoixTemplatizer) {
        const resortsRequest: any = !exceptions.includes('resorts')
          ? $voixNuxtApi(`/api/resorts`)
          : null
        const brandsRequest: any = !exceptions.includes('brands')
          ? $voixNuxtApi(`/api/brands`)
          : null

        let currentResortUrl = `/api/sites/${siteId.value}/resorts${pageStore.currentPage?.path}?language=${pageStore.currentPage?.language_code}`

        if (forceCurrentResort)
          currentResortUrl += '&force=1'

        const currentResortRequest: any = !exceptions.includes('currentResort')
          ? $voixNuxtApi(currentResortUrl)
          : null

        const footerCompanyMenuRequest: any = !exceptions.includes('footerCompanyMenu')
          ? $voixNuxtApi(`/api/sites/1/menus/Our%20Company%20Menu?language=${pageStore.currentPage?.language_code}`)
          : null
        const footerResourcesMenuRequest: any = !exceptions.includes('footerResourcesMenu')
          ? $voixNuxtApi(`/api/sites/1/menus/Footer%20Resources%20Menu?language=${pageStore.currentPage?.language_code}`)
          : null
        const footerTermsMenuRequest: any = !exceptions.includes('footerTermsMenu')
          ? $voixNuxtApi(`/api/sites/1/menus/Footer%20Terms%20Menu?language=${pageStore.currentPage?.language_code}`)
          : null

        const requests = []

        if (!exceptions.includes('resorts'))
          requests.push(resortsRequest)
        if (!exceptions.includes('brands'))
          requests.push(brandsRequest)
        if (!exceptions.includes('currentResort'))
          requests.push(currentResortRequest)
        if (!exceptions.includes('footerCompanyMenu'))
          requests.push(footerCompanyMenuRequest)
        if (!exceptions.includes('footerResourcesMenu'))
          requests.push(footerResourcesMenuRequest)
        if (!exceptions.includes('footerTermsMenu'))
          requests.push(footerTermsMenuRequest)

        await Promise.all(requests).then((values) => {
          let index = 0

          if (!exceptions.includes('resorts')) {
            resorts.value = values[index].data
            index++
          }
          if (!exceptions.includes('brands')) {
            brands.value = values[index].data
            index++
          }
          if (!exceptions.includes('currentResort')) {
            currentResort.value = values[index].data
            index++
          }
          if (!exceptions.includes('footerCompanyMenu')) {
            footerCompanyMenu.value = values[index].data
            index++
          }
          if (!exceptions.includes('footerResourcesMenu')) {
            footerResourcesMenu.value = values[index].data
            index++
          }
          if (!exceptions.includes('footerTermsMenu')) {
            footerTermsMenu.value = values[index].data
            index++
          }
        })
      }

      return {
        resorts: resorts.value,
        brands: brands.value,
        currentResort: currentResort.value,
        footerCompanyMenu: footerCompanyMenu.value,
        footerResourcesMenu: footerResourcesMenu.value,
        footerTermsMenu: footerTermsMenu.value,
      }
    },

    playaLayoutMounted: (currentResort: any) => {
      try {
        const id = window.setInterval(() => {
          if (typeof window.dataLayer === 'object' && currentResort.value?.data?.name) {
            window.dataLayer.push({
              hotelName: currentResort.value.data.name,
            })
            window.clearInterval(id)
          }
        }, 500)
      }
      catch (e) {
        console.error(e)
      }
    },

    setupHeader: (domainScriptId: string | null = null, links: Array<any> | null = null, scripts: Array<any> | null = null) => {
      interface Configuration {
        htmlAttrs: {
          lang: any
        }
        link: {
          rel: string
          href: string
          type?: string
          sizes?: string
        }[]
        script: {
          src?: string
          innerHTML?: string
          ['data-domain-script']?: string
          children?: string
          body?: boolean
        }[]
      }

      const configuration: Configuration = {
        htmlAttrs: {
          lang: languageCode,
        },
        link: [
          { rel: 'preconnect', href: 'https://api.iconify.design' },
        ],
        script: [],
      }

      let dissableGtm = false

      try {
        const route = useRoute()
        dissableGtm = Object.prototype.hasOwnProperty.call(route.query, 'dissable_gtm')
      }
      catch (e) { console.log('could  not check dissable_gtm', e) }

      if (!dissableGtm) {
        configuration.script.push({
          innerHTML: '(function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({\'gtm.start\': new Date().getTime(),event: \'gtm.js\'});var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != \'dataLayer\' ? \'&l=\' + l : \'\';j.async = true;j.src =\'https://www.googletagmanager.com/gtm.js?id=\' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, \'script\', \'dataLayer\', \'GTM-NZBGTK4\'); ',
        })
      }

      if (links !== null) {
        links.forEach((link) => {
          configuration.link.push(link as { rel: string, href: string, type?: string, sizes?: string })
        })
      }

      if (scripts !== null) {
        scripts.forEach((script) => {
          configuration.script.push(script as { src: string, innerHTML?: string, ['data-domain-script']?: string, children?: string, body?: boolean })
        })
      }

      // dissabling one trust
      // if (domainScriptId !== null) {
      //   configuration.script.push(
      //     {
      //       src: `https://cdn.cookielaw.org/consent/${domainScriptId}/OtAutoBlock.js`,
      //       body: true,
      //     },
      //   )
      //   configuration.script.push(
      //     {
      //       'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      //       'data-domain-script': domainScriptId,
      //       'body': true,
      //     },
      //   )
      //   configuration.script.push(
      //     {
      //       innerHTML: 'function OptanonWrapper() { }',
      //       body: true,
      //     },
      //   )
      // }

      useHead(configuration)
    },
  }
}
